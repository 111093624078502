<div class="header">
  <button
      class="header-menu"
      appRipple
      #serviceMenuButton
      tuiDropdownAlign="left"
      tuiDropdownDirection="bottom"
      tuiDropdownAppearance="menu-drop"
      [tuiDropdownMaxHeight]="470"
      [tuiDropdown]="serviceMenu"
      [tuiDropdownManual]="isOpenService"
      [tuiDropdownOffset]="10"
      (click)="toggleService()"
      (tuiActiveZoneChange)="onActiveZone($event)"
      (tuiObscured)="onObscured($event)"
  >
    <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
  </button>

  @if (appService.currentService$ | async; as cService) {
    <div class="header-logo flex align-items-center">
      <div class="header-logo-img">
        <img [ngSrc]="cService.logo" [width]="30" [height]="30">
      </div>
      <div class="header-logo-title">{{ cService.name }}</div>
    </div>
  }

  <div class="header-group">
    <app-input-search [disabled]="true"></app-input-search>

    <app-button styleClass="p-button-icon-only" severity="secondary" [text]="true" [disabled]="true">
      <app-icon iconName="support"></app-icon>
    </app-button>


    <div 
      class="header-notifications header-group-not"
      tuiDropdownAlign="right"
      tuiDropdownDirection="bottom"
      tuiDropdownAppearance="menu-drop"
      [tuiDropdownMaxHeight]="470"
      [tuiDropdown]="notification"
      [tuiDropdownManual]="isOpenNotification"
      [tuiDropdownOffset]="13"
      (click)="toggleNotification()"
      (tuiActiveZoneChange)="onActiveZone($event)"
      (tuiObscured)="onObscured($event)"
    >
      <app-button
        #notificationButton
        styleClass="p-button-icon-only"
        severity="secondary"
        [text]="true"
      >
        <app-icon iconName="notification"></app-icon>
      </app-button>

      @if (notifications && notifications[1]?.length) {
        <div class="not-badge">
          <p-badge [value]="notifications[1].length?.toString()" severity="danger" />
        </div>
      }
    </div>

    @if (userModel.user) {
      <div
          class="header-group-user"
          #userMenuButton
          tuiDropdownAlign="right"
          tuiDropdownDirection="bottom"
          tuiDropdownAppearance="menu-drop"
          [tuiDropdownMaxHeight]="maxUserMenuHeight"
          [tuiDropdown]="userMenu"
          [tuiDropdownManual]="isOpen"
          [tuiDropdownOffset]="13"
          (click)="toggleMenu()"
          (tuiActiveZoneChange)="onActiveZone($event)"
          (tuiObscured)="onObscured($event)"
      >
        <app-avatar [name]="userModel.user.name" size="medium" [rounded]="true"></app-avatar>

        <div class="header-group-user_arrow">
          <tui-svg src="tuiIconChevronDownLarge"></tui-svg>
        </div>
      </div>
    }
  </div>
</div>

<ng-template #serviceMenu>
  <div class="s-menu" [ngStyle]="{'max-height': maxServiceMenuHeight + 'px'}">
    <div class="s-menu-top" #serviceMenuTop >
      <ng-container *ngTemplateOutlet="serviceCard; context: {service: appService.currentService$ | async, disabled: true}"></ng-container>
      <app-icon iconName="v"></app-icon>
    </div>
    <div class="s-menu-content">
      @if (appService.currentService$ | async; as cService) {
        <app-scrollable-accordion [openOnStart]="true" class="u-menu-content-item" [maxHeight]="maxServiceAccordionHeight">
          <div accordionHeader>
            <div class="flex align-items-center">
              <div class="u-menu-content-item_title flex-1 pr-1">
                Сервисы LookinSoft
              </div>
            </div>
          </div>
          <div accordionBody style="transform: translateX(-8px);">
            @for (item of appServicesWithProcessedPermissions(); track item.id) {
              @if (cService.id !== item.id) {
                <ng-container *ngTemplateOutlet="serviceCard; context: {service: item}"></ng-container>
              }
            } @empty {
              <p class="text-center p-1">Сервисов нет</p>
            }
          </div>
      </app-scrollable-accordion>
      }
    </div>
    <div class="s-menu-bottom" #serviceMenuBottom></div>
  </div>
</ng-template>

<ng-template #serviceCard let-service="service" let-disabled="disabled">
  <div class="s-card flex align-items-center"
       [class.cursor-pointer]="!disabled"
       appRipple
       [disable]="disabled"
       (click)="changeService(service, disabled)"
  >
    <app-avatar bgColor="#fff" [url]="service.logo"></app-avatar>
    <div class="s-card-title flex-1">{{ service.name }}</div>
  </div>
</ng-template>

<ng-template #userMenu>
  <div class="u-menu" [ngStyle]="{'max-height': maxUserMenuHeight + 'px'}">
    <div class="u-menu-top" #userMenuTop>
      <app-avatar
          [name]="userModel.user?.selectedOrganization?.title"
          [url]="userModel.user?.selectedOrganization?.logo"
          [bgColor]="userModel.user?.selectedOrganization?.color"
          size="large"
      ></app-avatar>
      <div class="u-menu-name">{{ userModel.user?.selectedOrganization?.title }}</div>
      @if (userHavePermissionForEditOrganization()) {
        <app-button [text]="true" size="small" styleClass="py-1 px-2" (onClick)="modalService.openOrganizationModal(true)">
          <i class="pi pi-pencil"></i>
          <span class="ml-2">Редактировать</span>
        </app-button>
      }
      @if (userModel.user.currentWorker()) {
        <app-worker-card style="max-width: 100%;" [worker]="userModel.user?.currentWorker()" (onWorkerClick)="openCurrentWorker()"></app-worker-card>
      }
    </div>
    <div class="u-menu-content">
      @if (changeOrgLoading) {
        <div class="u-menu-content-loading">
          <app-loader></app-loader>
        </div>
      }
      @if (activeOrganizations?.length) {
        <app-scrollable-accordion [openOnStart]="true" class="u-menu-content-item" [maxHeight]="maxAccordionHeight" [indentToRight]="8">
          <div accordionHeader>
            <div class="flex align-items-center">
              <div class="u-menu-content-item_title flex-1 pr-1">
                Организации
              </div>
              @if (activeOrganizations?.length) {
                <div class="counter ml-auto">{{ activeOrganization.length }}</div>
              }
            </div>
          </div>
          <div accordionBody>
            @for (item of activeOrganizations; track item.id) {
              <app-organization-card
                  [organization]="item.organization"
                  [worker]="item.worker"
                  [showDescription]="false"
                  [canSelect]="true"
                  (click)="selectOrganization(item)"
              ></app-organization-card>
            } @empty {
              <p class="text-center p-1">Организаций нет</p>
            }
          </div>
        </app-scrollable-accordion>
      }

      @if (invitation?.length) {
        <app-scrollable-accordion class="u-menu-content-item" [maxHeight]="maxAccordionHeight" [indentToRight]="8">
          <div accordionHeader>
            <div class="flex align-items-center">
              <div class="u-menu-content-item_title flex-1 pr-1">
                Приглашения
              </div>
              @if (invitation.length) {
                <div class="counter ml-auto">{{ invitation.length }}</div>
              }
            </div>
          </div>
          <div accordionBody>
            @if (loading) {
              <app-loader></app-loader>
            } @else {
              @for (item of invitation; track item.id) {
                <app-organization-card
                    [organization]="item.organization"
                    [worker]="item.worker"
                    [showDescription]="false"
                    [showAccept]="true"
                    (onReject)="rejectInvitation(item)"
                    (onAccept)="acceptInvitation(item)"
                ></app-organization-card>
              } @empty {
                <p class="text-center p-1">Приглашений нет</p>
              }
            }
          </div>
        </app-scrollable-accordion>
      }
    </div>
    <div class="u-menu-bottom" #userMenuBottom>
      <div class="u-menu-bottom-wrap">
        <app-button 
          class="w-full u-menu-bottom-btn" 
          label="Создать организацию" 
          icon="pi pi-plus" 
          severity="only-text"
          (onClick)="modalService.openOrganizationModal()"
        ></app-button>
        <app-button 
          class="w-full u-menu-bottom-btn"
          label="Выйти" icon="pi pi-sign-out"
          severity="only-text"
          [loading]="userModel.isLogoutLoading()"
          (onClick)="userModel.logout()"
        ></app-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #notification>
  <div class="notification">
    <div class="notification-top">
      <div class="notification-title">Уведомления</div>
    </div>
    <div class="notification-content">
      <p-tabView #tabView class="notification-tab-vuew">
        <p-tabPanel header="Непрочитанные">
          <ng-template pTemplate="content">
            <div style="margin-right: -8px; margin-top: 7px;">
              <app-scrollable-accordion
                  [maxHeight]="maxNotificationHeight"
                  [hideBorder]="true"
                  [openOnStart]="true"
              >
                <div accordionHeader>
                  <div class="u-menu-content-item_title">
                    Сервис
                  </div>
                </div>
                <div accordionBody style="margin: -4px 0 0;">
                  @if (notificationLoading || loading) {
                    <app-loader></app-loader>
                  } @else {
                    @for (item of notifications[1]; track item.id) {
                      <app-notification-card [item]="item" (onMakeRead)="notificationUpdate($event)"></app-notification-card>
                    } @empty {
                      <p class="text-center p-1">Нет новых уведомлений</p>
                    }
                  }
                </div>
              </app-scrollable-accordion>
            </div>
            @if (notifications[1]?.length) {
              <div class="notification-bottom">
                <div class="flex align-items-center flex-wrap gap-2" style="padding: 8px 0;">
                  <app-button label="Отметить все как прочитанные" icon="pi pi-check" severity="only-text" size="small" (onClick)="readAll()"></app-button>
                </div>
              </div>
            }
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Прочитанные">
          <ng-template pTemplate="content">
            <div style="margin-right: -8px; margin-top: 9px;">
              <app-scrollable-accordion
                  [maxHeight]="maxNotificationHeight"
                  [hideBorder]="true"
                  [openOnStart]="true"
              >
              <div accordionHeader>
                <div class="u-menu-content-item_title">
                  Сервис
                </div>
              </div>
                <div accordionBody style="margin: -4px 0 0;">
                  @if (notificationLoading || loading) {
                    <app-loader></app-loader>
                  } @else {
                    @for (item of notifications[2]; track item.id) {
                      <app-notification-card [item]="item"></app-notification-card>
                    } @empty {
                      <p class="text-center p-1">Нет прочитаных уведомлений</p>
                    }
                  }
                </div>
              </app-scrollable-accordion>
            </div>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>

  </div>
</ng-template>


<!--<h1 class="tui-text_h5">{{title}}</h1>-->

<!--<div class="page-header-side">-->
<!--  <tui-hosted-dropdown [content]="dropdownButtonContent" class="mr-3">-->
<!--    <button-->
<!--        tuiButton-->
<!--        [iconRight]="arrow"-->
<!--        [appearance]="dropdownButton.appearance"-->
<!--        [size]="dropdownButton.size"-->
<!--    >-->
<!--      {{dropdownButton.text}}-->
<!--    </button>-->
<!--  </tui-hosted-dropdown>-->

<!--  <button-->
<!--      tuiButton-->
<!--      [icon]="addButton.icon"-->
<!--      [appearance]="addButton.appearance"-->
<!--      [size]="addButton.size"-->
<!--      (click)="addButtonClick.emit()"-->
<!--  >-->
<!--    {{addButton.text}}-->
<!--  </button>-->
<!--</div>-->
