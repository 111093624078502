import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef, EventEmitter,
  forwardRef, HostListener,
  Injector,
  Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  CloseScrollStrategy,
  ConnectedOverlayPositionChange,
  ConnectionPositionPair,
  Overlay
} from '@angular/cdk/overlay'
import {TreeNode} from 'primeng/api';
import {BreakpointObserver} from '@angular/cdk/layout';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractNgModelComponent} from '@/app/core/abstracts/ng-model.component';
import {LookinSDK, TreeSelected, TriggerQueryAction, TriggerQueryEntity, TriggerTreeNodeQuery } from 'lookin-sdk';
import {HelperService} from '@/app/services/helper.service';
import {Tree} from 'primeng/tree';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {SelectOverlayWrapperComponent} from '@/app/components/form-components/select/overlay-wrapper/item';

/**
 * Represents a select component with options for single and multiple selection.
 *
 * @example
 *
 * <app-select
 *  [FormControl]='control'
 *  [treeItems]='[]'
 *  [type]="'single'"
 *></app-select>
 */
@Component({
  selector: 'app-select',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    TuiDestroyService
  ],

})
export class SelectComponent extends AbstractNgModelComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild(CdkOverlayOrigin) origin!: CdkOverlayOrigin;
  @ViewChild(CdkConnectedOverlay) connectedOverlay!: CdkConnectedOverlay;
  @ViewChild(SelectOverlayWrapperComponent) overlayWrapper!: SelectOverlayWrapperComponent;
  @ViewChild('tree') treeRef: Tree;
  /**
   * Header template ref
   */
  @Input() selectHeader: TemplateRef<any> = null;
  /**
   * Option template ref
   */
  @Input() selectOption: TemplateRef<any> = null;

  /**
   * Type of select
   */
  @Input() type: 'single' | 'multiple' = 'single';

  /**
   * Tree items
   */
  @Input() treeItems: TreeNode[];

  @Input() parentSlug: string;

  @Input() queryEntity: TriggerQueryEntity;

  @Input() queryAction: TriggerQueryAction;

  @Input() typeField: 'entity' | 'action';

  @Input() isSearchByDefault: boolean = false;

  @Input() isGQL: boolean = false;

  @Input() loading = false;

  @Input() fieldType: any;

  @Input() isRequestToEntity: boolean = false;

  @Output() onLoadChildren = new EventEmitter<string>();
  @Output() onLoadMore = new EventEmitter<void>();
  @Output() onSearch = new EventEmitter<string>();

  selected: any;
  scrollStrategy: CloseScrollStrategy;
  isMobile: boolean;

  currentScrollHeight: number = 0;
  isFirstLoad = true;
  private mutationObserver: MutationObserver;
  constructor(
    injector: Injector,
    private overlay:Overlay,
    private breakpointObserver: BreakpointObserver,
    private destroy$: TuiDestroyService,
  ) {
    super(injector);

    this.scrollStrategy = this.overlay.scrollStrategies.close();
    this.isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');

  }

  search = new FormControl();
  totalItems: number = 0;
  isOpen:boolean = false;
  isTop:boolean = false;
  positions:ConnectionPositionPair[] = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'center', overlayY: 'top' },
      0,
      0,
      'select-body-bottom'
    ),
    new ConnectionPositionPair(
      { originX: 'center', originY: 'bottom' },
      { overlayX: 'center', overlayY: 'bottom' },
      0,
      0,
      'select-body-top'
    ),
  ]


  ngOnInit() {
    this.currentScrollHeight = this.parentEl.scrollHeight;
    this.cdRef.markForCheck();
    if (this.isRequestToEntity) {
      this.getEntityFields(this.queryEntity.entity, null, null, (fields) => {
        this.treeItems = HelperService.toTree(fields.item.items);
        this.totalItems = this.getTotalItems();
        this.cdRef.markForCheck();
      });
    }

    this.search.valueChanges
      .pipe(
        filter(() => !this.isSearchByDefault),
        debounceTime(300),
        takeUntil(this.destroy$)
      ).subscribe(search => {

        if (this.isGQL) {
          this.onSearch.emit(search);
          return;
        }

        this.loading = true;
        this.cdRef.markForCheck();
        const getData = (field: TreeSelected<any>) => {
          this.treeItems = HelperService.toTree(field.item.items);
          this.totalItems = this.getTotalItems();
          this.loading = false;
          this.cdRef.markForCheck();
        }

        if (this.typeField === 'entity' || this.isRequestToEntity) {
          this.getEntityFields(this.parentSlug ?? this.queryEntity.entity, null, search, (fields) => {
            getData(fields)
          });
        } else {
          this.getActionFields(this.parentSlug ?? this.queryEntity.entity, null, search, (fields) => {
            getData(fields)
          });
        }
    })
  }

  ngAfterViewInit() {
    // This for update overlay position
    this.mutationObserver = new MutationObserver(() => {
      if (this.parentEl.scrollHeight !== this.currentScrollHeight && this.connectedOverlay && this.connectedOverlay.overlayRef) {
        this.currentScrollHeight = this.parentEl.scrollHeight;
        this.connectedOverlay.overlayRef.updatePosition();
      }
    });

    this.mutationObserver.observe(this.parentEl, {
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy() {
    this.mutationObserver.disconnect();
  }

  get parentEl() {
    return document.querySelector('.hat-scaffold__body-content') || document.documentElement;
  }

  /**
   * Retrieves the width of the origin element.
   *
   * @return {number} The width of the origin element, or 0 if the element is undefined or has no width.
   */
  get originWidth():number {
    const element = this.origin?.elementRef?.nativeElement as HTMLElement|undefined;
    return element?.offsetWidth??0;
  }


  /**
   * Toggles the state of an element.
   * If the element is disabled, it does not toggle the state.
   *
   *
   * @returns {void}
   */
  toggle() {
    if(this.disabled || this.readonly) return
    this.isOpen = !this.isOpen;
    const selected = Array.isArray(this.selected) ? this.selected[0] : this.selected;

    if (this.isSearchByDefault) {
      setTimeout(() => {
        if (this.treeRef?.filterViewChild?.nativeElement) {
          this.treeRef.filterViewChild.nativeElement?.focus();
        }
      })
    }

    if (this.isOpen && selected && selected.key && !this.search.value) {
      this.loading = true;
      setTimeout(() => {
        if (this.treeRef && !this.treeRef.filteredNodes?.length) {
          this.treeItems.forEach(item => {
            this.expandRecursive(item)
          });

          if (this.treeRef.serializedValue.length > 5) {
            this.goToItem(selected.key);
          }
        }
        this.loading = false;
        this.cdRef.markForCheck();
      })
    }
  }


  /**
   * Sets the isOpen property to false.
   */
  onClose() {
    this.isOpen = false;
    if (this.search.value) {
      this.search.setValue(null);
    }
  }


  /**
   * Handles the position change of the connected overlay.
   *
   * @param {ConnectedOverlayPositionChange} change - The position change event.
   */
  onPositionChange(change:ConnectedOverlayPositionChange) {
    this.isTop = change.connectionPair.overlayY!='top';
    this.cdRef.detectChanges();
  }


  /**
   * Invokes the onChangeModel method passing the selected value
   * and logs the selected value to the console.
   *
   * @method select
   *
   */
  select() {
    if (this.type === 'single' && this.selected && this.overlayWrapper) {
      this.overlayWrapper.close();
    }

    if (this.onChangeModel) {
      this.onChangeModel(this.selected);
      this.onTouchModel();
    }
  }


  /**
   * Removes an item from the selected list.
   *
   * @param {any} ev - The event that triggered the removal.
   * @param {TreeNode} item - The item to be removed.
   */
  remove(ev: any, item: TreeNode) {
    ev.stopPropagation();
    ev.preventDefault();
    this.selected = this.selected.filter(select => select.key !== item.key);
    if (this.onChangeModel) {
      this.onChangeModel(!this.selected.length ? null : this.selected);
    }
  }


  /**
   * Remove single item from the selection.
   *
   * @param {any} ev - The event object.
   */
  removeSingle(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();

    this.selected = null
    if (this.onChangeModel) {
      this.onChangeModel(this.selected);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { isSearchByDefault, fieldType, type, treeItems } = changes;
    const condFirst = isSearchByDefault && !isSearchByDefault?.firstChange && isSearchByDefault.currentValue !== isSearchByDefault.previousValue && this.value;
    const condSecond = fieldType && !fieldType?.firstChange && JSON.stringify(fieldType.currentValue) !== JSON.stringify(fieldType.previousValue);
    if ((condFirst || condSecond) && !this.isGQL) {
      if (this.queryEntity) {
        this.isFirstLoad = true;
        this.nodeGetValue();
      }
    }

    if (type && !type.firstChange && this.selected && type.currentValue !== type.previousValue) {
      if (type.currentValue === 'single') {
        this.selected = this.selected[0];
      } else  {
        this.selected = [this.selected];
      }
      this.select()
    }

    if (treeItems && JSON.stringify(treeItems?.currentValue) !== JSON.stringify(treeItems?.previousValue)) {
      this.totalItems = this.getTotalItems();
    }
  }

  writeValue(value: any) {
    super.writeValue(value);
    if (value) {
      this.selected = this.type === 'single' ? value : [...value];
      if (this.queryEntity) {
        this.nodeGetValue();
      }
    } else {
      this.selected = null
    }
  }

  nodeGetValue() {
    if (!this.isFirstLoad) {
      return;
    }
    this.isFirstLoad = false;
    const selectedKeys = !this.selected ? null : Array.isArray(this.selected) ? this.selected : [this.selected];
    if (!this.typeField || this.isSearchByDefault) {
      const selected = selectedKeys?.reduce((acc, key) => {
        const item = this.treeItems.find(item => item.key === key);
        acc.push(item);
        return acc;
      }, [])

      if (selectedKeys) {
        this.selected = this.type === 'single' ? selected[0] : selected;
        setTimeout(() => {
          this.select();
        }, 100);
      }
      return;
    }

    if (this.isGQL) {
      return;
    }

    const parentEntitySlug = this.isRequestToEntity ? this.queryEntity.entity : this.parentSlug ?? this.queryEntity.entity;
    this.loading = true;
    this.cdRef.markForCheck();
    const getData = (fields: TreeSelected<any>) => {
      this.treeItems = HelperService.toTree(fields.item.items);
      this.totalItems = this.getTotalItems();
      if (selectedKeys && fields.selectedValues) {
        this.selected = this.type === 'single' ? fields.selectedValues[0] : fields.selectedValues;
      }
      this.loading = false;
      this.cdRef.markForCheck();
      setTimeout(() => {
        this.select();
      }, 100);
    }
    if (this.typeField === 'entity' || this.isRequestToEntity) {
      this.getEntityFields(parentEntitySlug, selectedKeys, null, (fields) => {
        getData(fields)
      });
    } else {
      this.getActionFields(parentEntitySlug, selectedKeys, null, (fields) => {
        getData(fields)
      });
    }
  }

  nodeExpand(event: any) {
    if (event.node.children && event.node.children.length) {
      if (this.treeRef) {
        this.goToItem(event.node.key)
        this.cdRef.markForCheck();
      }
      return;
    }

    if (event.node.leaf) {
      return;
    }

    this.loading = true;
    const getData = (fields: TreeSelected<any>) => {
      event.node.children = HelperService.toTree(fields.item.items);
      if (this.treeRef) {
        this.goToItem(event.node.key, () => {
          this.loading = false;
        })
        this.cdRef.markForCheck();
      }
    }

    if (this.typeField === 'entity' || this.isRequestToEntity) {
      this.getEntityFields(event.node.key, null, null, (fields) => {
        getData(fields)
      });
    } else {
      this.getActionFields(event.node.key, null, null, (fields) => {
        getData(fields)
      });
    }
  }

  getEntityFields(parentEntitySlug: string, selectedKeys: string[] | null, search: string | null, callback: (fields) => void) {
    const query: TriggerTreeNodeQuery = {
      search,
      fieldType: this.fieldType ? this.fieldType : {
        type: null,
        relatedEntitySlug: null
      },
      parentEntitySlug,
      perPage: 30,
      page: 1
    };
    try {
      LookinSDK.restTriggerModel.getEntityFields(this.queryEntity, query, selectedKeys).then((fields) => {
        callback(fields);
      })
    } catch (error) {
      console.error(error)
    }
  }

  getActionFields(parentEntitySlug: string, selectedKeys: string[] | null, search: string | null, callback: (fields) => void) {
    const query: TriggerTreeNodeQuery = {
      search,
      fieldType: {
        type: null,
        relatedEntitySlug: null
      },
      parentEntitySlug,
      perPage: 30,
      page: 1
    };

    try {
      LookinSDK.restTriggerModel.getActionFields(this.queryEntity, this.queryAction, query, selectedKeys).then((fields) => {
        callback(fields);
      })
    } catch (error) {
      console.error(error)
    }
  }

  goToItem(key: string, callback?: () => void) {
    const idx = this.treeRef.serializedValue.findIndex((item: any) => item.node.key === key);
    if (idx < 0 || this.treeRef.filteredNodes?.length) {
      return;
    }
    setTimeout(() => {
      this.treeRef.scrollToVirtualIndex(idx);
      this.treeRef.updateSerializedValue();
      if (callback) {
        callback()
      }
      this.cdRef.markForCheck();
    })
  }

  expandRecursive(node: TreeNode) {
    if (node.children && node.children.length) {
      node.expanded = true;
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode);
      });
      this.treeRef.updateSerializedValue();
      this.cdRef.markForCheck();
    }
  }

  getLabel(item: TreeNode) {
    return item.data?.selectedTitle ?? item.label
  }

  getTotalItems() {
    let count = 0;

    const walk = (elements) => {
      if (count > 6) {
        return;
      }
      for (let i = 0; i < elements.length; i++) {
        count++;
        if (elements[i].children?.length) {
          walk(elements[i].children);
        }
      }
    };

    walk(this.treeItems);

    return count;
  }

  trackByIndex(idx: number): number {
    return idx;
  }
}
