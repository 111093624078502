import { Injectable, Injector } from '@angular/core';
import { SakuraModelService } from '@/app/core/services/sakura-model.service';
import { FormElementSelectGqlConfiguration } from '@/app/entities/form/form-element-select.entity';
import { Dictionary } from '@/app/entities/dictionary/dictionary.entity';
import { plainToInstance } from 'class-transformer';
import { GQLWhereBuilder } from 'lookin-sdk';
import {TreeNode} from 'primeng/api';
import {HelperService} from '@/app/services/helper.service';
import {SchemaParser} from '@/app/core/classes/schema-parser';
import {environment} from '@/environments/environment';

@Injectable({
              providedIn: 'root',
            })
export class DictionaryListService extends SakuraModelService {
  constructor(injector: Injector) {
    super(injector);
  }

  getDictionaryListByGql(
    gql: FormElementSelectGqlConfiguration,
    page: number = 1,
    search: string = null,
    onlyPaginatorInfo: boolean = false,
    first: number = 1000,
    callback: (res: TreeNode[], pagination: any) => void) {
    //
    // let additionalWhere = '';
    // if (gql.additionalWhere) {
    //   additionalWhere = GQLWhereBuilder.createFromRaw(gql.additionalWhere).toGQLString();
    //   additionalWhere = `,[${additionalWhere}]`;
    // }

    // console.log(search);
    // console.log(gql.searchByFieldOperator);
    // console.log(gql.searchByField);

    const where = {
      AND: [],
    };

    if (gql?.additionalWhere) {
      where.AND.push(gql.additionalWhere);
    }

    if (search) {
      where.AND.push({
                       [gql.searchByFieldOperator]: gql.searchByField.map(item => ({
                         column: item.column,
                         value: search,
                         operator: item.operator,
                       })),
                     });
    }


    // const where = search
    //   ? `where: {${gql.searchByFieldOperator}: [
    //     ${gql.searchByField.map(field => `{column: ${field.column}, value: "${search}", operator: ${field.operator}}`).join(',')}
    //   ]${additionalWhere}}`
    //   :``;
    let data = '';
    if (!onlyPaginatorInfo && gql.fields.length) {
      data = `data {${gql.fields.join(' ')}}`
    }

    this.post({
                operationName: 'GetDictionaryList',
                query: `query GetDictionaryList {
        ${gql.entity}(
          ${where.AND.length ? `where: ${GQLWhereBuilder.createFromRaw(where).toGQLString()}`:''}
          page: ${page}
          first: ${first}
        ) {
          ${data}
          paginatorInfo {
            currentPage
            firstItem
            hasMorePages
            lastPage
            total
            perPage
            lastItem
          }
        }
      }`,
              }, {}, `${environment.gqlUrl}/graphql`, (res) => {
      if (res.status) {
        if (res.result.data[gql.entity]?.data?.length) {
          const tree = HelperService.toTree(res.result.data[gql.entity]?.data?.map((item) => {
            return {
              key: item[gql.fieldAsId],
              label: gql.fieldAsTitleSchema ? SchemaParser.interpolate(gql.fieldAsTitleSchema, item) : item.title,
              data: {...item}
            }
          }))
          callback(tree, res.result.data[gql.entity].paginatorInfo);
        } else if (res.result.data[gql.entity].paginatorInfo) {
          callback([], res.result.data[gql.entity].paginatorInfo);
        } else {
          callback([], null);
        }
      }

    });
  }
}
